import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import { lazy as loadable } from '@loadable/component'

import * as Constants from '../constants'
import * as Suspense from '../suspense'
import * as CombinedSelectors from '../combined-selectors'
import * as Configuration from '../configuration'
import { BriteLoginDrawer } from '../brite-login-drawer'
import { Footer } from '../footer'
import { Home } from '../home'
import { LocationPicker } from '../locale/change-location'
import { LoyaltyDrawer } from '../loyalty-drawer'
import { MainMenu } from '../main-menu'
import { PrivateRoute } from '../private-route'
import { PromotionsDrawer } from '../promotions-drawer'
import { RaffleBanner } from '../raffle-banner'
import { Registration } from '../registration'
import { SportsbookDirect } from '../sportsbook-direct'
import { Wallet } from '../wallet'
import { createBrandedComponent } from '../create-branded-component'
import { useCookieConsent } from '../use-cookie-consent'
import { useRaffleCampaigns } from '../raffle-page/use-fetch-raffle-campaigns'
import { useScrollToTop } from '../use-scroll-to-top'

const Account = loadable(() => import('../account'))
const Casino = loadable(() => import('../casino'))
const CryptoLandingPageContainer = loadable(() =>
  import('../crypto-landing-page')
)
const DevTools = loadable(() => import('../dev-tools'))
const ExpertsArea = loadable(() => import('../experts-area'))
const Faq = loadable(() => import('../faq'))
const GameInfoPage = loadable(() => import('../game-info-page'))
const LiveCasino = loadable(() => import('../live-casino'))
const Mahjong = loadable(() => import('../mahjong'))
const Logout = loadable(() => import('../logout'))
const LoginDrawer = loadable(() => import('../login-drawer'))
const ManualLoginDrawer = loadable(() => import('../manual-login-drawer'))
const Moments = loadable(() => import('../moments'))
const NotFound = loadable(() => import('../not-found'))
const PasswordRecovery = loadable(() => import('../password-recovery'))
const PasswordReset = loadable(() => import('../password-reset'))
const PromotionTermsAndConditionsPage = loadable(() =>
  import('../promotion-terms-and-conditions-page')
)
const RaffePage = loadable(() => import('../raffle-page'))

const BRANDED_STATIC_COMPONENTS = {
  playboom: loadable(() => import('../static-page')),
  playboom24: loadable(() => import('../static-page')),
  wettenlive: loadable(() => import('../static-page-wettenlive')),
  casinosky: loadable(() => import('../static-page-casinosky')),
  casinoskyjp: loadable(() => import('../static-page-casinosky')),
  paradiseplay: loadable(() => import('../static-page-casinosky')),
}

const StaticPage = createBrandedComponent(
  'StaticPage',
  BRANDED_STATIC_COMPONENTS
)

const StorePage = loadable(() => import('../store-page'))
const TermsAndConditions = loadable(() => import('../terms-and-conditions'))

export function Primary() {
  const [ref, handleScrollToTop] = useScrollToTop()
  const location = ReactRouter.useLocation()
  const cookieConsentEnabled = useCookieConsent()
  const storeEnabled = ReactRedux.useSelector(state =>
    CombinedSelectors.isInventoryStoreEnabled(state)
  )
  const expertBlogEnabled = ReactRedux.useSelector(state =>
    Configuration.expertBlogEnabled(state.configuration)
  )
  const brandName = ReactRedux.useSelector(state =>
    Configuration.getBrand(state.configuration)
  )
  const isMahjongEnabled = ReactRedux.useSelector(state =>
    Configuration.isMahjongEnabled(state.configuration)
  )

  const env = ReactRedux.useSelector(state =>
    Configuration.getBrand(state.configuration)
  )

  const isRaffleEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleEnabled(state.configuration)
  )

  const isRaffleLinkEnabled = ReactRedux.useSelector(state =>
    Configuration.isRaffleLinkEnabled(state.configuration)
  )

  const { isRunning } = useRaffleCampaigns()

  const pragmaticLiveLobbyGameIds = {
    playboom: {
      staging: 11466,
      production: 11466,
    },
    wettenlive: {
      staging: 11466,
      production: 11466,
    },
    casinosky: {
      staging: 11466,
      production: 11466,
    },
    casinoskyjp: {
      staging: 11466,
      production: 5061,
    },
    paradiseplay: {
      staging: 11466,
      production: 11466,
    },
  }

  const pragmaticLiveLobbyGameId = ReactRedux.useSelector(state =>
    R.path(
      [brandName, Configuration.getEnvironment(state.configuration)],
      pragmaticLiveLobbyGameIds
    )
  )

  return (
    <React.Fragment>
      <ReactRouter.Route path="/:id?" component={MainMenu} />
      <Common.Box ref={ref}>
        <Common.Box
          minHeight={[
            'calc(var(--window-inner-height, 100vh) - 46px)',
            null,
            '100vh',
          ]}
          display="flex"
          flexDirection="column"
        >
          {isRaffleEnabled && isRaffleLinkEnabled && isRunning ? (
            <RaffleBanner />
          ) : null}
          <Suspense.Boundary>
            <ReactRouter.Switch>
              <ReactRouter.Route exact path="/">
                <Home onScrollToTop={handleScrollToTop} />
              </ReactRouter.Route>

              <ReactRouter.Route exact path="/landing-pages/:id?">
                <Home onScrollToTop={handleScrollToTop} />
              </ReactRouter.Route>

              <ReactRouter.Redirect
                from="/locks"
                to={{ pathname: '/', search: location.search }}
              />

              <ReactRouter.Redirect
                from="/bullcasinopromo"
                to={{ pathname: '/', search: location.search }}
              />

              {env === 'staging' && (
                <ReactRouter.Route
                  path="/change-location"
                  component={LocationPicker}
                />
              )}

              {storeEnabled && (
                <ReactRouter.Route
                  path="/store/:id?/:stake?"
                  component={StorePage}
                />
              )}

              {isRaffleEnabled && (
                <ReactRouter.Route path="/raffle" component={RaffePage} />
              )}

              <ReactRouter.Route
                path="/casino/games/:gameId"
                component={GameInfoPage}
              />

              <ReactRouter.Route path="/casino" component={Casino} />

              {brandName !== Constants.Brands.CASINOSKYJP ? (
                <ReactRouter.Route
                  path="/sports"
                  component={SportsbookDirect}
                />
              ) : null}

              {brandName === Constants.Brands.PLAYBOOM && (
                <ReactRouter.Route path="/moments" component={Moments} />
              )}

              {isMahjongEnabled && (
                <ReactRouter.Route path="/mahjong" component={Mahjong} />
              )}

              <PrivateRoute path="/account" component={Account} />
              <ReactRouter.Route
                path="/crypto"
                component={CryptoLandingPageContainer}
              />

              <ReactRouter.Route path="/live-casino" component={LiveCasino} />

              {(brandName === Constants.Brands.PLAYBOOM ||
                brandName === Constants.Brands.WETTENLIVE ||
                brandName === Constants.Brands.CASINOSKY ||
                brandName === Constants.Brands.CASINOSKYJP ||
                brandName === Constants.Brands.PARADISEPLAY) && (
                <ReactRouter.Redirect
                  path="/pragmatic-live"
                  to={{
                    pathname: `/casino/games/${pragmaticLiveLobbyGameId}`,
                    search: '?referrer=pragmatic-live&mode=classic',
                  }}
                />
              )}

              {expertBlogEnabled && (
                <ReactRouter.Route
                  path="/experts-area"
                  component={ExpertsArea}
                />
              )}

              <ReactRouter.Route
                exact
                path="/password-recovery"
                component={PasswordRecovery}
              />

              <ReactRouter.Redirect
                exact
                from="/password/:token"
                to="/password-reset/:token"
              />
              <ReactRouter.Route
                path="/password-reset/:token"
                component={PasswordReset}
              />

              <ReactRouter.Route
                path={R.filter(Boolean, [
                  '/(about)',
                  cookieConsentEnabled && '/(cookie-policy)',
                  '/(privacy-policy)',
                  '/(responsible-gaming)',
                  '/(dispute-resolution)',
                  '/(anti-money-laundering)',
                  '/(fairness-rng-testing-methods)',
                  '/(kyc-policies)',
                  '/(self-exclusion)',
                  '/(security)',
                  '/(accounts-payouts-bonuses)',
                ])}
                component={StaticPage}
              />

              <ReactRouter.Route
                path="/promotions/:key"
                component={PromotionTermsAndConditionsPage}
              />

              <ReactRouter.Route path="/faq" component={Faq} />

              <ReactRouter.Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />

              <ReactRouter.Route path="/logout" component={Logout} />

              {/* Keep existing functionality for people who still have it for a while */}
              <ReactRouter.Redirect
                from="/login"
                to={{ pathname: '/', search: '?login=me' }}
              />

              <ReactRouter.Route path="/dev-tools">
                <DevTools />
              </ReactRouter.Route>

              <ReactRouter.Route
                path="/500"
                render={() => {
                  throw new Error('Failure was successful')
                }}
              />

              <ReactRouter.Route component={NotFound} />
            </ReactRouter.Switch>
          </Suspense.Boundary>
        </Common.Box>
        <Footer />
      </Common.Box>
      <PromotionsDrawer />
      <LoyaltyDrawer />
      <Wallet />
      <LoginDrawer />
      <ManualLoginDrawer />
      <BriteLoginDrawer />
      <Registration />
    </React.Fragment>
  )
}

// For @loadable/component
export default Primary
